.form-input {
  @apply bg-gray-100
    w-full
    rounded
    border
    border-gray-300
    p-2
    text-sm
    mb-2;
}

.field_with_errors .form-input {
  @apply border-red-200 bg-red-50;
}

.form-label {
  @apply text-sm
    block
    text-gray-600
    mb-2;
}

.form-select {
  @apply p-2;
}

input[type="file"] {
  @apply text-sm;
}

input[type="file"] ~ .carousel-root {
  @apply mt-2;
}

input::placeholder,
textarea::placeholder {
  @apply text-gray-400;
}

textarea:focus-visible {
  @apply outline-none;
}

.form-link-preview {
  @apply -mt-2 bg-gray-100 border-gray-300 border border-t-0 text-sm rounded-b p-4;
}

.react-datepicker-wrapper {
  @apply w-full;
}

ul.form-errors {
  @apply bg-red-400
    border
    border-red-500
    text-sm
    rounded
    text-white
    px-2
    py-1
    mb-4
    list-none;

  & li {
    @apply mb-1 list-none;
  }

  & li:last-of-type {
    @apply mb-0;
  }
}

/* watchlist creation form */

ul.suggestion-list {
  @apply mt-0 w-full bg-gray-100 border border-gray-300 border-t-0 rounded-b overflow-hidden;

  & li {
    @apply cursor-pointer p-2 flex items-center border-b;
  }

  & li:last-of-type {
    @apply border-b-0;
  }
}
