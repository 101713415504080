.onboarding {
  @apply absolute
    top-20
    right-8
    z-50
    max-w-sm

    bg-white
    p-6
    rounded-lg
    shadow-2xl;

  & h2 {
    @apply text-base
      font-bold
      mb-2;
  }

  & .progress-bar {
    @apply w-full
      rounded-full
      h-3
      overflow-hidden
      mt-1 mb-4
      bg-green-200;

    & .bar {
      @apply h-full
        bg-green-600
        text-right
        text-white
        pr-1
        align-middle
        leading-8;
    }
  }

  & .steps {
    @apply text-sm;

    & .step {
      @apply leading-relaxed;

      & svg {
        @apply text-green-500;
      }
    }

    & .step-complete {
      & a {
        @apply text-gray-400 border-b border-gray-300;
      }
    }

    & .step-incomplete {
      & svg {
        @apply text-gray-400;
      }

      & a {
        @apply text-gray-800 border-b border-gray-400;
      }
    }
  }
}
