aside#aside {
  @apply bg-transparent pr-8;
  color: var(--pitch-text-color);

  & ul li {
    @apply list-none;
  }

  & button span {
    @apply text-base flex-1 text-left whitespace-nowrap;
  }

  & button {
    @apply text-left w-full;
  }

  & button[aria-expanded="true"] {
    background: url("../images/arrow-expanded.svg");
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
  }

  & button[aria-expanded="false"] {
    background: url("../images/arrow-collapsed.svg");
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
  }

  & .li-active {
    width: 100%;
    height: auto;
    background: var(--pitch-text-color);
    border-radius: 5px;
    color: white;
    display: block;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  & .li-inactive {
    @apply bg-transparent;
  }

  & .dropdown-list li a {
    text-indent: 35px;
    display: block;
    width: 100%;
    height: auto;
    font-size: 14px;
  }
}
