.permissions-banner {
  @apply py-4 px-6 bg-yellow-100 text-gray-800 rounded-md mb-4 shadow;

  & h2 {
    @apply font-bold
      text-base
      mb-2;

    & span.float-right {
      @apply cursor-pointer;
    }
  }

  & dl {
    @apply text-sm;

    & dt {
      @apply clear-left float-left mr-1 mb-1 font-semibold;
    }

    & dd {
      @apply clear-right mb-1;
    }
  }
}
