body.homepage {
  background: var(--homepage-background);

  & .navigation {
    background: var(--pitch-orange);
    @apply p-3;
  }
}

h2.homepage-subtitle {
  @apply font-bold text-gray-900;
}

.orange-block {
  background: var(--pitch-orange);
}

.black-block {
  @apply bg-black;
}

.pitch-block {
  background: #000 url("../images/homepage/pitch.png");
  @apply bg-cover;
  height: 900px;
}
