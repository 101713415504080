@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;700&display=swap");

@import "tailwindcss/base";
@import "tailwindcss/components";

@import "./variables";

@import "./components/general";
@import "./components/buttons";
@import "./components/overlay";
@import "./components/navigation";
@import "./components/cards";
@import "./components/forms";
@import "./components/avatars";
@import "./components/icons";
@import "./components/updates";
@import "./components/toasts";
@import "./components/banners";
@import "./components/tables";
@import "./components/onboarding";
@import "./components/navbar";
@import "./components/homepage";
@import "./components/copy";
@import "./components/aside";
@import "./components/admin";
@import "./components/bar";

/* Carousel and customization */
@import "react-responsive-carousel/lib/styles/carousel.min.css";
@import "react-datepicker/dist/react-datepicker.css";
@import "./components/carousel";

@import "tailwindcss/utilities";
