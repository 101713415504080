.icon {
  @apply w-4
    h-4
    mr-2
    inline;
  vertical-align: sub;
}

.icon-small {
  @apply mr-1
    w-3
    h-3
    align-middle;
}

.feather {
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}
