/* Base button style */

.button {
  @apply bg-white
    text-gray-800
    border
    border-gray-300

    bg-gradient-to-b
    from-white
    to-gray-50

    rounded
    shadow-sm

    inline-block
    px-2 py-1
    text-sm
    cursor-pointer
    font-normal
    normal-case
    box-border
    whitespace-nowrap;

  & svg {
    @apply opacity-50;

    vertical-align: sub;
  }

  &:hover,
  &.button-hover {
    @apply border-gray-600;
  }

  &.button-small {
    @apply text-sm;

    & .icon {
      @apply mr-1 h-3 w-3;
    }
  }

  &.button-homepage-orange {
    background: var(--pitch-orange);
    @apply text-white border-none shadow-none p-6 py-2;
  }

  &.button-black {
    @apply bg-gray-100
      text-white
      bg-gradient-to-b
      from-black
      to-black
      border-0;
  }

  &.button-circle {
    @apply inline-flex items-center justify-center w-6 h-6 rounded-full;
  }
}

/* Button variants */

.button-primary {
  @apply border-2
    font-medium
    border-blue-500
    text-blue-600
    shadow-md;

  &:hover {
    @apply border-blue-700 text-blue-700;
  }
}

.button-green {
  @apply border-green-500
    text-green-600;

  &:hover {
    @apply border-green-700
      text-green-700;
  }

  &.button-green-filled {
    @apply bg-none text-white bg-green-500;

    &:hover {
      @apply text-white bg-green-600 border-green-700;
    }

    & svg {
      @apply opacity-70;
    }
  }
}

.button-red {
  @apply border-red-500
    text-red-600;

  &:hover {
    @apply border-red-700
      text-red-700;
  }
}

.button-disabled {
  @apply line-through
    bg-gray-400
    border-gray-400
    text-gray-600;
}

/* Specific button uses */

.button-toggle {
  @apply whitespace-nowrap;
}

.button-dropdown {
  @apply font-normal
    text-sm
    normal-case
    absolute
    flex
    flex-col
    border
    inset-x-0
    z-20
    rounded-b
    overflow-hidden
    border-gray-300;

  top: 100%;

  &:hover,
  &.button-hover {
    @apply border-gray-400 shadow;
    border-top-width: 0;
  }

  border-top-width: 0;

  & > * {
    @apply px-2
      py-1
      cursor-pointer
      bg-gray-50
      text-gray-800;
  }
  & > *:hover {
    @apply bg-gray-200;
  }
}

.button-follow {
  @apply items-center border p-1 px-2 rounded cursor-pointer text-gray-500;
}

.button-following {
  @apply button-follow border-green-500 text-green-600;
}
