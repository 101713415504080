.avatar-container {
  @apply relative
    inline-block
    w-12
    h-12;
}

.avatar-verified::after {
  @apply absolute;

  content: url("../images/verified.svg");
  top: -5px;
  right: -8px;
  transform: scale(0.75);
}

.avatar-small,
.avatar-small .person-avatar {
  @apply h-6
    w-6;
}

.avatar-tiny,
.avatar-tiny .person-avatar {
  @apply h-4
    w-4;
}

/* do not show verified marker on small avatars */
.avatar-small.avatar-verified::after {
  content: none;
}

.person-avatar {
  @apply rounded-full
    w-12
    h-12
    max-h-full
    max-w-full;
}

.avatar-large,
.avatar-large .person-avatar {
  @apply w-28 h-28 object-cover;
}

.logo-container {
  @apply p-4
    border-0 md:border-2
    md:border-gray-100
    md:rounded-md
    text-center;

  & .company-logo {
    @apply mx-auto;
  }
}

.company-logo {
  @apply w-12
    h-auto;
}

.company-logo-small {
  @apply w-8
    h-auto;
}

.company-logo-tiny {
  @apply w-4 h-auto;
}

.company-logo-medium {
  @apply w-12 h-auto;
}

.company-logo-large {
  @apply w-28 h-auto;
}
