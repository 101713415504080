.navigation {
  @apply bg-white
    mb-4 md:mb-8
    px-6
    py-3
    text-gray-800
    text-base
    shadow-sm
    flex
    fixed
    w-full
    z-20
    top-0;

  & nav {
    @apply z-20
      absolute md:relative
      top-11 left-0 right-0 md:top-0
      border-b border-t md:border-none
      md:space-x-2
      flex-col md:flex-row;
  }

  & .pills {
    & a {
      @apply text-gray-500;
    }

    & .active {
      @apply text-gray-900 font-semibold;
    }
  }

  & .person-avatar {
    @apply focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white;
  }
}

.pagination {
  @apply text-sm mt-4 p-4 text-center;

  & .page,
  & .prev,
  & .first,
  & .last,
  & .next {
    @apply mr-1;
  }

  & .page {
    &.current {
      @apply font-bold no-underline;
    }
  }
}

.dropdown {
  @apply relative inline-block;

  & .dropdown-trigger {
    @apply cursor-pointer;
  }

  & .dropdown-content {
    @apply overflow-hidden absolute w-48 z-50 right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-gray-200/70 focus:outline-none;

    &.dropdown-right {
      @apply left-0 right-auto;
    }

    & a {
      @apply text-sm block px-4 py-2 text-gray-700 hover:bg-gray-100;
    }

    & .dropdown-separator {
      @apply bg-gray-100/70 h-1 block;
    }

    & .dropdown-section-title {
      @apply text-xs text-gray-500 px-4 py-2 bg-zinc-50 border-b border-zinc-100;
    }
  }
}
