.navbar {
  @apply mb-6 text-sm flex;

  & .navbar-tabs {
    @apply bg-gray-200 rounded-md p-1.5 flex grow-0 space-x-2;

    & .tab {
      @apply text-sm p-1 px-2 text-gray-500;

      & svg {
        @apply opacity-25 mr-1;
      }

      &.active {
        @apply text-gray-800 bg-white rounded-md shadow;

        & svg {
          @apply opacity-75 mr-1;
        }
      }
    }
  }
}
