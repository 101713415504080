#overlay {
  @apply fixed
    inset-0
    bg-gray-700
    bg-opacity-75
    transition-opacity
    z-50

    backdrop-filter
    backdrop-blur-sm

    flex
    justify-center
    items-center;

  & .overlay-content {
    @apply bg-white w-full max-w-md mx-auto card;
  }
}
