.carousel .thumbs-wrapper {
  @apply m-0 mt-3;
  & .thumbs {
    @apply flex items-center;
  }
}

.carousel .thumb {
  @apply border border-gray-200 p-1;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  @apply border border-gray-400 rounded;
}

.carousel .thumb img {
  @apply rounded-sm;
}

.carousel .control-dots .dot {
  @apply shadow mx-1;
}

.carousel .slide {
  @apply bg-gray-100 flex content-center items-center justify-items-center justify-center;
  & .trash {
    @apply hidden;
  }
}

.carousel .slide img {
  @apply rounded;
}
