body {
  @apply bg-gray-100;
  color: var(--pitch-text-color);
}

ul {
  & li {
    @apply list-disc list-inside;
  }
}

.container {
  @apply w-full
    max-w-6xl
    mx-auto
    px-4
    md:px-0;
}

.container-full {
  @apply max-w-none w-full px-4;
}

.headline-main {
  @apply text-2xl
    leading-normal
    font-semibold
    text-center;
}

.headline-large {
  @apply text-lg
    leading-normal
    font-bold;
}

.headline-medium {
  @apply leading-normal
    font-bold;
}

.help {
  @apply rounded
    border
    border-yellow-200
    bg-yellow-50
    px-3
    py-2
    mb-6
    text-sm
    leading-relaxed;
}

.help-green {
  @apply bg-green-50 border-green-200;
}

.help-gray {
  @apply bg-gray-50 border-gray-200 text-gray-600;
}

.errors {
  @apply rounded
    bg-red-100
    border
    border-red-200
    py-2 px-3
    text-sm
    shadow-sm;

  & ul {
    @apply list-none;
  }
}

.tag {
  @apply whitespace-nowrap inline-block relative mb-1 mr-1 py-1 px-2
    text-xs text-gray-500 bg-gray-50 border rounded-full;

  &.tag-current {
    @apply border-green-400 text-green-700;
  }
}

.visibility-indicator {
  @apply font-normal opacity-50 bg-gray-100 text-gray-600 ml-2 px-2 py-1 text-xs rounded;
}

.social-link .icon {
  @apply mr-1;
}

.social-link :last-child {
  @apply mr-0;
}

.link-grey {
  @apply text-gray-600;
}

.link-underline {
  @apply border-b
    border-gray-300;
}

.profile-section {
  @apply rounded-lg
    shadow-md
    bg-white
    p-6
    mb-10;
}

.section {
  @apply rounded
    bg-white
    p-4
    mb-8;
}

.section:last-of-type {
  @apply mb-0;
}

.section-headline {
  @apply text-base
    font-semibold
    mb-4
    text-gray-800;
}

.section-subtitle {
  @apply text-sm
    text-gray-400
    font-semibold
    mb-4;
}

.grey-container {
  @apply rounded
    p-4
    bg-gray-100
    border
    border-gray-200;
}

.private-marker {
  @apply rounded
    inline-block
    py-1
    px-2
    bg-blue-100
    text-sm
    border
    border-blue-100;
}

.text-pitch {
  color: var(--pitch-orange);
}

/* Text sections */

.text {
  @apply text-base leading-relaxed;

  & p,
  & ul {
    @apply mb-3;
  }

  & p:last-of-type,
  & ul:last-of-type {
    @apply mb-0;
  }

  & a {
    @apply underline;
  }
}

.header-gradient {
  @apply bg-gradient-to-b from-gray-500 to-pitchblue;
}

.header-gradient-small {
  @apply bg-gradient-to-b from-gray-700 to-gray-800;
}

/* Used when showing numbers, dollar amounts, etc */
.number {
  @apply font-mono text-sm text-gray-800;
}

/* Profile specific classes */
/* We could eventually make this less specific, if we stop using profile-section independently altogether */
.profile-page .profile-section {
  @apply rounded-none
    shadow-none
    bg-none
    p-0
    mb-14;

  & .last-profile-section {
    @apply mb-0 pb-0 border-none;
  }
}
