.table {
  @apply w-full text-sm;
}

.table thead {
  @apply bg-gray-50 divide-y divide-gray-200 text-left;
}

.table tbody {
  @apply divide-y divide-gray-200;
}

.table thead tr {
  @apply border-b border-gray-300;
}

.table thead tr {
  @apply border-b;
}

.table th,
.table td {
  @apply py-2 px-3;
}

.table th {
  @apply text-xs font-medium text-gray-500;
}

.table-container {
  @apply rounded overflow-hidden border-b border-gray-200 sm:rounded-lg mb-8 border;
}
