.card {
  @apply rounded-md
    bg-white
    p-6
    shadow;
}

.card-company {
  @apply card
    p-4;
}

.card-user {
  @apply card
    p-4;
}

.card-login {
  @apply card
    max-w-md
    mx-auto;
}

.card-grey {
  @apply bg-gray-100;
}
