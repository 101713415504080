.copy {
  & h1,
  & h2,
  & h3 {
    @apply font-bold mt-3 mb-4;
  }

  & h1 {
    @apply text-3xl mt-0;
  }

  & h2 {
    @apply text-2xl;
  }

  & h3 {
    @apply text-xl;
  }

  & p,
  & ul,
  & dl,
  & dt,
  & dd {
    @apply mb-4;
  }

  & dt {
    @apply font-bold;
  }

  & a {
    @apply underline text-green-600;
  }

  & ul li {
    @apply pl-8;
  }
}
