/* Update sections */

.update {
  @apply text-sm
    border
    border-gray-200
    mb-4
    rounded-md;
}

.update-content {
  @apply flex-grow
    p-4;
}

.update-body a {
  @apply link-underline;
}

.update-screenshot {
  @apply float-right
    max-w-xs
    mb-4
    ml-4
    rounded
    p-2
    bg-gray-100
    border
    border-gray-300;
}

.update-meta {
  @apply bg-gray-100 text-gray-400
    border-t
    p-4
    py-2
    rounded-b-md;
}

/* Update attachments, links */

.update-link {
  @apply flex
    border
    border-yellow-200
    bg-yellow-50
    p-3
    items-center
    rounded
    mt-4;
}

.update-attachments {
  @apply flex flex-col space-y-1
    mt-2
    border
    border-yellow-200
    bg-yellow-50
    p-3
    rounded;
}
