.toast {
  @apply text-sm
    rounded-lg
    bg-white
    border-2
    fixed top-24 right-10 z-40 shadow-lg w-72 rounded-lg;

  & .toast-header {
    @apply px-4 py-2 text-white font-bold;
  }

  & .toast-content {
    @apply px-4 py-3 leading-relaxed;
  }

  &.toast-notice {
    @apply border-green-500;

    & .toast-header {
      @apply bg-green-500;
    }
  }
  &.toast-alert {
    @apply border-red-400;

    & .toast-header {
      @apply bg-red-400;
    }
  }

  &.toast-dismissable {
    @apply cursor-pointer;
  }

  &.toast-information {
    @apply bg-gray-400;
  }
}
